.proceed-payment-container {
  width: 30%;
  height: auto;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  .payment-infos {
    position: relative;
    margin-bottom: 2.5rem;
    display: grid;
    h5 {
      text-align: center;
    }
    label {
      width: 100%;
    }
    .year-cvc {
      display: flex;
      .input-custom {
        width: 50% !important;
      }
    }
    .input-custom {
      margin: 0 !important;
      &-large {
        width: calc(100% - 1.7rem) !important;
      }
    }
  }
}

@media all and (max-width: 735px) {
  .proceed-payment-container {
    width: 80% !important;
  }
}
