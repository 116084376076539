.docs-recap{
  background-color: #FFFF;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .docs-recap-title{
    color: black;
    font-weight: bold !important;
    font-size: 1rem !important;
    width: 80%;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    text-transform: none !important;
  }
  .docs-recap-list-container {
    margin-left: 2rem;
    width: 80%;
    div {
      color: #66605e;
      font-weight: normal;
      display: flex;
      align-items: baseline;
      .docs-recap-marker {
        display: inline-block;
        height: 0.2rem;
        width: 0.2rem;
        border: 2px solid #66605e;
        border-radius: 50%;
        margin-right: 0.5rem;
      }
      p {
        display: inline-block;
        width: fit-content;
        margin: 0.2rem;
      }
      span {
        text-decoration: underline;
        font-size: 1rem;
      }
    }
  }
}