.apply-header {
  padding-bottom: 0.5rem;
  .alert-msg {
    background-color: rgba(255, 92, 57, 0.09);
    border: solid 1px var(--orange);
    display: inline;
  }
  .app-header {
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-element {
        display: flex;
        align-items: center;
        h3 {
          padding-right: 1rem;
        }
      }
    }
  }
}
.apply-list-container {
  position: fixed;
  width: 60%;
  .apply-list {
    overflow-y: scroll;
    // position: absolute;
    width: 100%;
    height: 100%;
    padding-bottom: 1rem;
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
    .docs-justif-top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .docs-justif-left {
        text-align: justify;
        // background-color: #fff;
        width: auto;
        margin-top: 0;
        margin-right: 1rem;
        margin-bottom: 5rem;
        // border: solid 1px #000;
        padding: 0.5rem;
      }
    }
    .other-docs {
      background-color: #fff;
      display: flex;
      padding: 2.5rem;
      .other-left {
        width: 65%;
        .upload-orange-container {
          > input {
            display: none;
          }
          .upload-orange {
            position: relative;
            cursor: pointer;
            display: inline-block;
            p {
              width: fit-content;
              color: var(--orange);
              display: flex;
              align-items: center;
            }
          }
        }
      }
      .other-right {
        border: solid 1px #000;
        padding: 1rem;
        width: 35%;
      }
    }
  }
}
@media all and (max-width: 680px) {
  .other-docs {
    padding: 0.5rem !important;
    display: block !important;
    .other-left {
      width: 100% !important;
    }
    .other-right {
      width: 85% !important;
    }
  }
  .docs-justif-top {
    display: block !important;
    .docs-justif-left {
      width: 90% !important;
      margin: auto;
    }
  }
}
@media all and (max-width: 940px) {
  .docs-justif-top {
    display: block !important;
    .docs-justif-left {
      margin: auto;
    }
  }
}
