.payment-container {
  width: 30%;
  height: auto;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  .payment-txt {
    margin-bottom: 2.5rem;
    p:first-of-type {
      padding-top: 1rem !important;
    }
    h5 {
      position: relative;
      display: inline-block;
      span {
        position: absolute;
        bottom: -10px;
        left: 0;
        height: 3px;
        background-color: #000;
        width: 100%;
      }
    }
  }
}
@media all and (max-width: 735px) {
  .payment-container {
    width: 80%;
  }
}
