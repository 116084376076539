.Spinner {
  position: relative;
  width: 100%;
  z-index: 10;
  MuiBackdrop-root open {
    color: #fff;
    z-index: 1201;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
