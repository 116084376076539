.folder-grade {
  .folder-header {
    margin: 0 1rem;
    position: sticky;
    .validate {
      display: flex;
      justify-content: space-between;
    }
  }
  &-scroll {
    width: 100%;
    &-content {
      background-color: #fff;
      .body {
        width: 98%;
        padding: 0 1%;
      }
    }
    .footer {
      padding: 1%;
      background-color: var(--violet);
      color: #fff !important;
      textarea {
        resize: none;
      }
    }
  }
  .jury-info {
    display: flex;
    justify-content: space-between;
  }
}
.folder-grade-scroll {
  display: flex;
  position: relative;
  height: 60vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .folder-grade-scroll-content {
    width: 100%;
  }
}
