.subscribe-layout {
    width: 60%;
    margin: auto;
    .subscribe-logo {
      width: 100%;
      text-align: center;
      padding: 2rem 0;
      img {
        width: 6rem;
      }
      .toolbox {
        display: grid;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        .flag-container {
          display: flex;
          justify-content: flex-end;
          .flag {
            width: fit-content;
            border: none;
            background-color: transparent;
            img {
              border: solid 2px transparent;
              width: 35px;
            }
          }
          .fr,
          .en {
            transition: all 0.3s ease;
            img {
              border: solid 2px var(--orange);
            }
          }
        }
      }
    }
    .subscribe-header {
      padding-bottom: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .subscribe-list-container {
      position: fixed;
      width: 60%;
      .subscribe-list {
        overflow-y: scroll;
        position: absolute;
        width: 100%;
        height: 98%;
        padding-bottom: 1rem;
        .selected {
          border: solid 2px var(--orange);
        }
        &::-webkit-scrollbar {
          background-color: transparent;
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }
        .delete-line {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  @media all and (max-width: 595px) and (min-width: 426px) {
    .subscribe-layout {
      width: 95%;
      .subscribe-list-container {
        width: 95%;
      }
    }
  }
  @media all and (max-width: 425px) {
    .subscribe-layout {
      width: 95%;
      .subscribe-list-container {
        width: 95%;
      }
    }
    .subscribe-logo {
      width: 50% !important;
    }
  }
  