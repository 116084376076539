.personal-data-policy {
  padding: 3rem 0;
  .personal-data-policy-container {
    margin: auto;
    background-color: #fff;
    padding: 1.5rem;
    width:80%;
    h2, i {
      display: block;
      margin: auto;
      text-align: center;
    }
    div {
      margin: 1rem 0;
    }
    .personal-data-policy-list {
      margin: 0 0 0 1rem;
    }
  }


}