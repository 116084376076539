.profil {
  background-color: #fef0ec;
  .profil-switch-container {
    padding-bottom: 0.5rem;
    .profile-menu-desktop {
      display: flex;
      overflow: hidden;
      span {
        text-align: center;
      }
      .active {
        background-color: var(--orange);
        border: solid 1px var(--orange) !important;
        color: #fff;
        &:hover {
          background-color: var(--orange);
          border: solid 1px var(--orange);
        }
      }
      &:hover {
        color: #000;
      }
      .MuiListItem-root {
        border: solid 1px rgba(0, 0, 0, 0.1);
        &:first-child {
          border-radius: 5px 0 0 5px;
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
        }
      }
    }
    .profile-menu-mobile {
      display: none;
    }
    .save-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .apply-list-container {
    background-color: #fef0ec;
    position: fixed;
    width: 60%;
    .apply-list {
      overflow-y: scroll;
      // position: absolute;
      width: 100%;
      height: 100%;
      padding-bottom: 1rem;
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }
      .delete-line {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
@media all and (min-width: 550px) and (max-width: 1020px) {
  .apply-list-container {
    width: 75% !important;
  }
}
@media all and (max-width: 549px) {
  .apply-list-container {
    width: 95% !important;
  }
}
@media all and (max-width: 768px) {
  .profile-menu-desktop {
    display: none !important;
  }
  .profile-menu-mobile {
    display: block !important;
  }
}
