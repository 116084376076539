.valide-payment-container {
  width: 50%;
  height: 50%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  .payment-right {
    width: 65%;
    padding: 2rem;
    .payment-txt {
      margin-bottom: 2.5rem;
      p:first-of-type {
        padding-top: 1rem !important;
      }
      h5 {
        width: 70%;
        position: relative;
        display: inline-block;
        span {
          position: absolute;
          bottom: -10px;
          left: 0;
          height: 3px;
          background-color: #000;
          width: 100%;
        }
      }
    }
    .payment-btn {
    }
  }
  .payment-left {
    width: 35%;
    overflow: hidden;
    display: flex;
    align-items: center;
    img {
      height: 100%;
    }
  }
}
@media all and (max-width: 1330px) and (min-width: 735px) {
  .valide-payment-container {
    width: 90%;
  }
}
@media all and (max-width: 734px) {
  .valide-payment-container {
    display: block !important;
    width: 90%;
    .payment-left {
      display: none;
    }
    .payment-right {
      background-color: #fff;
      width: calc(100% - 4rem);
      h5 {
        width: 100% !important;
      }
    }
  }
}
