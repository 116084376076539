.missing-header {
  padding-bottom: 0.5rem;
  .alert-msg {
    background-color: rgba(255, 92, 57, 0.09);
    border: solid 1px var(--orange);
    display: inline;
  }
  .miss-header {
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-element {
        display: flex;
        align-items: center;
        h3 {
          padding-right: 1rem;
        }
      }
    }
  }
}
.missing-list-container {
  position: fixed;
  width: 60%;
  .missing-list {
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    height: 100%;
    padding-bottom: 1rem;
    .missing {
      border: solid 2px transparent;
      margin: 1rem 0;
      padding: 1rem;
      width: 75%;
      background-color: #fff;
      text-align: left;
    }
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
  }
}
