.home {
  width: 100%;
  z-index: 0;
  // padding-bottom: 2rem;
  &-image {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    img {
      width: 100%;
    }
  }
  &-content {
    position: absolute;
    left: 50%;
    height: 50%;
    bottom: 2%;
    transform: translateX(-50%);
    z-index: 1;
    width: 40%;
    // margin: 0.5rem auto;
    background-color: var(--orange);
    border-radius: 1000px 1000px 0 0;
    text-align: center;
    padding-top: 15%;
    // padding-bottom: 2rem;
    .white-logo {
      margin: auto;
      width: 40%;
      img {
        width: 70%;
      }
    }
    h3 {
      color: #fff;
    }
    .home-button {
      margin: auto;
      margin: 3rem auto;
      width: fit-content;
      // height: 15rem;
      button {
        width: 100%;
        height: 100%;
        padding: 1rem;
        background-color: #fff;
        outline: none;
        box-shadow: none;
        border: 0;
        h4 {
          text-transform: uppercase;
        }
      }
      .accepted-application {
        background-color: #000;
        cursor: initial;
        h4 {
          color: #fff;
        }
      }
    }
  }
}
@media all and (max-width: 425px) {
  .home-image {
    height: 50vh;
    overflow: hidden;
    img {
      width: auto;
      height: 100%;
    }
  }
  .home-content {
    width: 90%;
  }
}
@media all and (min-width: 426px) and (max-width: 768px) {
  .home-content {
    width: 50%;
  }
}
