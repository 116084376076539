.uploaded {
  display: flex;
  align-items: center;
  p {
    margin: 0.2rem 0;
  }
  button {
    margin: 0 1rem !important;
  }
}
