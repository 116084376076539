.international-education-selection {
    margin-top: 1rem;
    width: 80%;
    display: flex;
    justify-content: space-between;
    .card-button {
      display: flex;
      width: 45%;
      background-color: #fff;
      border: solid 1px transparent;
      text-align: left;
      padding: 0.8rem;
      &.selected {
        border: solid 2px #fe856a;
      }
      &-icon {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 70%;
        }
      }
      &-text {
        width: 70%;
        p {
          margin: 0.2rem 0 !important;
          color: #000;
        }
        span {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
}
.info-text {
  font-size: 0.9rem !important;
}
