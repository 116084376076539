.authenticate {
  padding: 3rem 0;
  .authenticate-container {
    margin: auto;
    background-color: #fff;
    padding: 1.5rem;
    width: 25%;
    .cgu {
      margin-bottom: 1rem;
      input[type='checkbox'] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        width: 0.8em;
        height: 0.8em;
        padding: 0.5rem !important;
        border: 1px solid rgba(0, 0, 0, 0.5) !important;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
        display: inline-grid;
        place-content: center;
      }
      input[type='checkbox']::before {
        content: '';
        width: 0.7em;
        height: 0.7em;
        -webkit-clip-path: polygon(
          31% 85%,
          88% 11%,
          100% 11%,
          30% 100%,
          0 45%,
          11% 45%
        );
        clip-path: polygon(
          31% 85%,
          88% 11%,
          100% 11%,
          30% 100%,
          0 45%,
          11% 45%
        );
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
        background-color: CanvasText;
      }
      input[type='checkbox']:checked::before {
        transform: scale(1);
      }
    }
  }
  &__header {
    text-align: center;
    img {
      width: 50%;
    }
    h5 {
      text-transform: uppercase;
    }
    &__language-cont {
      transition: all 0.3s ease;
      button {
        position: relative;
        width: 2.5rem;
        border: none;
        background-color: inherit;
        font-weight: 900;
        color: rgb(105, 105, 105);
        img {
          opacity: 0.6;
          width: 100%;
        }
      }
      .fr,
      .en {
        transition: all 0.3s ease;
        border-bottom: solid 1px #000;
        img {
          opacity: 1;
        }
      }
      .language-btn-selected {
        border-bottom: solid 2px rgb(83, 83, 83);
        color: rgb(83, 83, 83);
      }
    }
  }
  .authenticate__form {
    display: flex;
    flex-direction: column;
    &__link {
      text-align: center;
      text-decoration: none;
      color: grey;
      font-size: 0.8rem;
      font-weight: 900;
      margin: 0.5rem;
    }
    .icon-hide {
      background-color: transparent;
    }
  }
}

@media all and (max-width: 520px) {
  .authenticate {
    padding: 0;
    .authenticate-container {
      width: 85% !important;
      min-height: calc(100vh - 3rem);
    }
  }
}
@media all and (min-width: 521px) and (max-width: 768px) {
  .authenticate {
    .authenticate-container {
      width: 70% !important;
    }
  }
}
