.contact {
  display: flex;
  &-form-container {
    height: fit-content;
    width: 60%;
    border-right: solid 1px #000;
    h5 {
      margin: 2rem 0 !important;
    }
    label {
      text-transform: uppercase;
    }
    .contact-form {
      position: relative;
      width: 90%;
      padding-left: 5%;
      padding-right: 5%;
      &-div {
        display: grid;
        label {
          width: 100%;
        }
        select {
          width: 100% !important;
          margin-bottom: 1.5rem !important;
          padding: 0.5rem;
        }
        textarea {
          resize: none;
          width: calc(100% - 1.6rem) !important;
          height: 12rem;
          margin-bottom: 1.5rem;
        }
        h6 {
          margin: 1.5rem 0 !important;
        }
      }
    }
  }
  &-form-contact {
    width: 60%;
    h6 {
      text-transform: uppercase;
    }
    .contact-container {
      width: 100%;
      padding-left: 5%;
      .infos-contact {
        background-color: #3f2a56;
        color: #fff;
        padding: 1rem 2rem;
        h6 {
          color: #fff !important;
        }
        .contact-card {
          padding: 0.5rem 0;
          position: relative;
          width: 100%;
          .contact-title {
            text-transform: uppercase;
            opacity: 0.6;
          }
          span {
            position: absolute;
            width: 10%;
            height: 2px;
            background-color: #fff;
          }
        }
        p {
          font-size: 1.2rem !important;
        }
      }
      .see-faq {
        background-color: #fff;
        padding: 1rem 2rem;
        h5 {
          text-transform: uppercase !important;
        }
      }
    }
  }
}
@media all and (max-width: 785px) {
  .contact {
    display: block;
    &-form-container {
      border-right: none !important;
      width: 100%;
      .contact-form {
        padding: 0 !important;
        width: 100%;
      }
    }
    &-form-contact {
      width: 100%;
      .contact-container {
        width: 100%;
        padding: 0 !important;
      }
    }
  }
}
