.incomplete-folder {
  .incomplete-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .incomplete-comment {
    &-text {
      p {
        display: inline-block;
        background-color: #fff;
        margin: 0.5rem !important;
        border: solid 1px #000;
        padding: 0.5rem;
      }
    }
  }
}
