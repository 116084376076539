.legend {
  display: flex;
  align-items: center;
  .status {
    position: relative;
    p {
      margin: 16px;
    }
    .finish {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #12ae09;
      -webkit-box-shadow: 0px 0px 6px 0px #12ae09;
      box-shadow: 0px 0px 6px 0px #12ae09;
      margin-top: 22px;
    }
    .proceed {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #ff5c39;
      -webkit-box-shadow: 0px 0px 6px 0px #ff5c39;
      box-shadow: 0px 0px 6px 0px #ff5c39;
      margin-top: 22px;
    }
  }
}
.folder-table {
  padding: 1rem 0;
  width: 100%;
  border-spacing: 0;
  thead {
    text-align: center;
  }
  tbody {
    text-align: center;
    tr {
      cursor: pointer;
      transition: all 0.3s ease;
    }
    tr:hover {
      background-color: var(--violet);
      color: white;
    }
    .status {
      width: max-content;
      margin: auto;
      position: relative;
      .finish {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        span {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: #12ae09;
          -webkit-box-shadow: 0px 0px 6px 0px #12ae09;
          box-shadow: 0px 0px 6px 0px #12ae09;
        }
      }
      .proceed {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        span {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: #ff5c39;
          -webkit-box-shadow: 0px 0px 6px 0px #ff5c39;
          box-shadow: 0px 0px 6px 0px #ff5c39;
        }
      }
    }
  }
}
