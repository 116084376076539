.faqs {
  background-color: #fff;
  width: 60%;
  margin: 2rem auto;
  padding: 1rem;
  .faq-search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    input {
      width: 40%;
    }
    button {
      background-color: transparent;
      border: none;
    }
  }
  .faq__header {
    padding: 1rem 0;
  }
  .accordion-item {
    border-bottom: solid 1px #000;
    .question-title {
      cursor: pointer;
      color: #ff5c39;
      text-transform: uppercase;
      font-weight: normal;
    }
    .accordion-content {
      img {
        max-width: 100%;
      }
    }
  }
}
.faqs-not-found {
  width: 60%;
  margin: auto;
}
@media all and (max-width: 425px) {
  .faqs {
    width: 85%;
  }
}
