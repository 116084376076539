.grades {
  width: 80% !important;
  .grade-return {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    &:hover {
      background-color: transparent;
    }
    .return-icon {
      position: relative;
      display: flex;
      width: 25px;
      padding-right: 5px;
      img {
        width: 100%;
      }
    }
  }
  .attachment-list {
    margin: 0 1rem;
  }
  .inline-field {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .small-break-line {
    width: 90%;
  }
  .grade-content {
    display: flex;
    position: relative;
    height: 80vh;
    .grade-left {
      background-color: #fff;
      width: 39%;
      margin-right: 1%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }
      .jury-switch {
        display: flex;
        justify-content: center;
        align-items: center;
        .switch-menu {
          width: 50%;
          display: flex;
          overflow: hidden;
          span {
            text-align: center;
          }
          .active {
            background-color: var(--orange);
            border: solid 1px var(--orange) !important;
            color: #fff;
            &:hover {
              background-color: var(--orange);
              border: solid 1px var(--orange);
            }
          }
          &:hover {
            color: #000;
          }
          .MuiListItem-root {
            border: solid 1px rgba(0, 0, 0, 0.1);
            &:first-child {
              border-radius: 5px 0 0 5px;
            }
            &:last-child {
              border-radius: 0 5px 5px 0;
            }
          }
        }
        .jury-filters {
          width: 40%;
          display: flex;
          justify-content: flex-end;
          input {
            width: 70%;
          }
          select {
            width: 30%;
            margin-left: 1rem !important;
          }
        }
      }
    }
    .grade-right {
      width: 59%;
      margin-left: 1%;
      background-color: #fff;
      .attachment-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .attachement-content {
        margin: 1rem;
        .attachement-container {
          display: -webkit-box;
          height: 93%;
          scroll-behavior: smooth;
          .attachement {
            width: 100%;
            height: 100%;
            margin: auto;
            text-align: center;
            .arrows {
              display: flex;
              align-items: center;
              justify-content: space-between;
              button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 1rem;
                border: none;
                background-color: transparent;
                border-radius: 100%;
              }
            }
            .embed-file {
              width: auto;
              max-width: 100%;
              max-height: 90%;
            }
            p {
              text-align: center;
            }
          }
        
        }
      }
    }
  }
}
