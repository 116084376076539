.agent-list {
  .agent-applicant-header {
    display: flex;
    margin-bottom: 2rem;
    .btn-custom {
      border: none;
      width: 20%;
      padding: 1rem;
      background-color: var(--violet);
      color: #fff;
      border-radius: 10px;
      .left {
        width: 100%;
      }
    }
    .right {
      padding: 1rem;
    }
  }
  .agent-table {
    width: 100%;
    background-color: #fff;
    padding: 1.5rem;
    .agent-title-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .agent-filters {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        input {
          width: 70%;
        }
        select {
          width: 30%;
          margin-left: 1rem !important;
        }
      }
    }
  }
}
@media all and (max-width: 768px) {
  .agent-list {
    .agent-applicant-header {
      .btn-custom {
        width: 40% !important;
      }
    }
  }
}
