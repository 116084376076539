.create-end {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  &-container {
    background-color: #fff;
    width: 70%;
    margin: auto;
    .end-header {
      padding: 2rem;
    }
    .end-missing-elements {
      background-color: #000;
      padding: 2rem;
      color: #fff;
      .diploma-name {
        padding-top: 0.6rem;
      }
    }
  }
}
@media all and (max-width: 768px) {
  .create-end {
    width: 85%;
    &-container {
      width: 100%;
    }
  }
}
