.embed-doc {
  width: 100%;
  max-width: 100%;
  max-height: 90%;
  height: 100vh;
}
.embed-img {
  width: 100%;
  max-width: 100%;
  max-height: 90%;
}
.grade-return {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 1rem 0 1rem 0;
  &:hover {
    background-color: transparent;
  }
  .return-icon {
    position: relative;
    display: flex;
    width: 25px;
    padding-right: 5px;
    img {
      width: 100%;
    }
  }
}
