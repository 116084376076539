:root {
  --form-control-color: var(--orange);
}
.catalogue {
  display: flex;
  height: 80vh;
  .filtres.mobile {
    display: none;
  }
  .filtres.desktop {
    display: block;
    padding: 5.5rem 1rem;
    width: 20%;
    background-color: #fff;
    height: calc(100vh - 246px);
    .filtre-container {
      overflow-y: scroll;
      height: 100%;
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }
    }
    .erase-filtre {
      display: flex;
      justify-content: space-between;
      align-items: center;      
    }
    .filtre-list {
      width: 80%;
      margin: auto;
      display: grid;    
      .filtre-title {
        font-size: 1.3rem;
      }
      div {
        margin: 0.3rem 0;
        input[type='checkbox'] {
          -webkit-appearance: none;
          appearance: none;
          background-color: #fff;
          margin: 0;
          font: inherit;
          width: 0.8em;
          height: 0.8em;
          padding: 0.5rem !important;
          border: 1px solid rgba(0, 0, 0, 0.5) !important;
          border-radius: 0.15em;
          transform: translateY(-0.075em);
          display: inline-grid;
          place-content: center;
        }
        input[type='checkbox']::before {
          content: '';
          width: 0.7em;
          height: 0.7em;
          -webkit-clip-path: polygon(
            31% 85%,
            88% 11%,
            100% 11%,
            30% 100%,
            0 45%,
            11% 45%
          );
          clip-path: polygon(
            31% 85%,
            88% 11%,
            100% 11%,
            30% 100%,
            0 45%,
            11% 45%
          );
          transform: scale(0);
          transform-origin: bottom left;
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em var(--form-control-color);
          background-color: CanvasText;
        }
        input[type='checkbox']:checked::before {
          transform: scale(1);
        }
      }
    }
  }
  .formations-container {
    padding: 2rem 1rem;
    width: 60%;
    .formations-header {
      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .filter-mobile {
        display: none;
      }
    }
    .formations-list-container {
      position: fixed;
      width: 60%;
      .formations-list {
        overflow-y: scroll;
        position: absolute;
        width: 100%;
        height: 100%;
        padding-bottom: 1rem;
        .formation-closed {
          background-color: #ffff;
          padding: 1.5rem;
          font-weight: bold;
          span {
            font-weight: bold;
            font-size: 1.4rem;
            text-decoration: underline;
          };
        }
        .formation {
          border: solid 2px transparent;
          margin: 1rem 0;
          padding: 1rem;
          width: 95%;
          background-color: #fff;
          text-align: left;
          p {
            color: #696969;
          }
          &-ecole-date {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .date {
              text-align: right;
            }
            p {
              margin: 0 !important;
            }
          }
        }
        .formation.active {
          border: solid 2px var(--orange);
        }
        &::-webkit-scrollbar {
          background-color: transparent;
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }
      }
    }
  }
}
@media all and (max-width: 768px) {
  .catalogue {
    display: block;
    .filtres.desktop {
      display: none !important;
    }
    .mobile {
      overflow-y: scroll;
      z-index: 1;
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }
    }
    .filtres.mobile {
      position: absolute;
      width: 90%;
      display: block !important;
      background-color: #fff;
      .filtre-list {
        width: 80%;
        margin: auto;
        display: grid;
        p {
          margin: 0.5rem 0;
        }
        div {
          input[type='checkbox'] {
            -webkit-appearance: none;
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            width: 0.8em;
            height: 0.8em;
            padding: 0.5rem !important;
            border: 1px solid rgba(0, 0, 0, 0.5) !important;
            border-radius: 0.15em;
            transform: translateY(-0.075em);
            display: inline-grid;
            place-content: center;
          }
          input[type='checkbox']::before {
            content: '';
            width: 0.7em;
            height: 0.7em;
            -webkit-clip-path: polygon(
              31% 85%,
              88% 11%,
              100% 11%,
              30% 100%,
              0 45%,
              11% 45%
            );
            clip-path: polygon(
              31% 85%,
              88% 11%,
              100% 11%,
              30% 100%,
              0 45%,
              11% 45%
            );
            transform: scale(0);
            transform-origin: bottom left;
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            background-color: CanvasText;
          }
          input[type='checkbox']:checked::before {
            transform: scale(1);
          }
        }
      }
    }
    .formations-container {
      width: 90% !important;
      .formations-header-btn {
        display: flex;
        .filter-mobile {
          margin-left: 0.5rem !important;
          display: block !important;
        }
      }
      .formations-list-container {
        width: 90%;
        button {
          width: 100% !important;
        }
      }
    }
  }
}
