.multi-select {
  width: 53% !important;
  font-family: 'futura' !important;
  font-size: 0.9rem !important;
  margin-bottom: 0.5rem !important;
  background-color: #fff;
  font-weight: normal;
  color: var(--darkviolet);
  border: solid 1px #e5e5e5 !important;
  outline: none;
  border-radius: 0px !important;
  font-weight: normal;
  fieldset,
  fieldset:focus {
    border-color: transparent !important;
    border-width: 0 !important;
  }
  div {
    padding: 14px 14px !important;
  }
  svg {
    color: black;
    font-size: 1.3rem;
    right: 0px;
  }
}
