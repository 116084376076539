.profile-created-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media all and (max-width: 735px) {
  .profile-created-container {
    width: 80%;
  }
}
