.education-sytem {
  width: 80%;
  display: flex;
  justify-content: space-between;
  .card-button {
    /*cursor à enlever quand parcours réactivés*/
    cursor: default;
    display: flex;
    width: 45%;
    background-color: #fff;
    border: solid 2px rgba(0, 0, 0, 0.3);
    text-align: left;
    padding: 0.8rem;
    &.selected {
      border: solid 2px #fe856a;
    }
    &-icon {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 70%;
      }
    }
    &-text {
      width: 70%;
      p {
        margin: 0.2rem 0 !important;
        color: #000;
      }
      span {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
@media all and (max-width: 768px) {
  .education-sytem {
    display: block;
    margin: auto;
    .card-button {
      margin: 1rem 0;
      width: 100%;
    }
  }
}
