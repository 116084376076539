.jury-list {
  width: 80%;
  .jury-header {
    display: flex;
    margin-bottom: 2rem;
    .left {
      width: 30%;
      padding: 1rem;
      background-color: var(--violet);
      color: #fff;
      border-radius: 20px;
    }
    .right {
      padding: 1rem;
    }
  }
  .jury-table {
    background-color: #fff;
    padding: 1.5rem;
    .jury-switch {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .switch-menu {
        width: 30%;
        display: flex;
        overflow: hidden;
        span {
          text-align: center;
        }
        .active {
          background-color: var(--orange);
          border: solid 1px var(--orange) !important;
          color: #fff;
          &:hover {
            background-color: var(--orange);
            border: solid 1px var(--orange);
          }
        }
        &:hover {
          color: #000;
        }
        .MuiListItem-root {
          border: solid 1px rgba(0, 0, 0, 0.1);
          &:first-child {
            border-radius: 5px 0 0 5px;
          }
          &:last-child {
            border-radius: 0 5px 5px 0;
          }
        }
      }
      .jury-filters {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        input {
          width: 70%;
        }
        select {
          width: 30%;
          margin-left: 1rem !important;
        }
      }
    }
  }
}
