.choose-date {
  display: flex;
  .choose-left {
    padding: 1rem;
    width: 40%;
    background-color: var(--violet);
    color: #fff;
    .white-logo {
      width: 60%;
      margin: auto;
      padding: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
      }
    }
  }
  .choose-right {
    position: relative;
    padding: 1rem;
    width: 60%;
    background-color: #fff;
    h5 {
      width: 50%;
      position: relative;
      display: inline-block;
      span {
        position: absolute;
        bottom: -10px;
        left: 0;
        height: 3px;
        background-color: #000;
        width: 100%;
      }
    }
    .calendar {
      padding-top: 2rem;
      width: 80%;
      margin: auto;
      .hours {
        display: block;
        width: fit-content;
        height: fit-content;
        .hour {
          display: none;
        }
        .hourSelected {
          border: 2.5px solid var(--orange);
        }
        label {
          display: block;
          width: fit-content;
          height: fit-content;
          background-color: #fff0ec;
          padding: 0.6rem 1rem;
        }
      }
      .no-date-avi {
        position: relative;
        width: 95%;
        padding: 2.5%;
        height: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .valide-date {
      width: 88%;
      padding: 6%;
      // position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
@media all and (max-width: 768px) {
  .choose-date {
    display: block !important;
    .choose-left {
      width: 90% !important;
    }
    .choose-right {
      width: 90% !important;
    }
  }
}
