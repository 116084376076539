.tooltip {
  display: inline;
  position: relative;
  button {
    background-color: transparent !important;
    border: none !important;
  }
  .tooltip-content {
    position: absolute;
    z-index: 50;
    display: block;
    left: 0.5rem;
    top: 1rem;
    background-color: #fff;
    border: solid 1px var(--orange);
    width: 320px;
    padding: 0.5rem;
    .tooltip-text {
      max-width: 320px;
      font-size: 0.8rem !important;
      color: var(--orange);
      margin: 0;
      text-transform: initial !important;
    }
  }
  .tooltip-content.collapsed {
    display: none;
  }
}
