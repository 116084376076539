.custom-checkbox-box {
  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    width: 0.8em;
    height: 0.8em;
    padding: 0.5rem !important;
    border: 1px solid rgba(0, 0, 0, 0.5) !important;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: inline-grid;
    place-content: center;
  }
  input[type='checkbox']::before {
    content: '';
    width: 0.7em;
    height: 0.7em;
    -webkit-clip-path: polygon(
      31% 85%,
      88% 11%,
      100% 11%,
      30% 100%,
      0 45%,
      11% 45%
    );
    clip-path: polygon(31% 85%, 88% 11%, 100% 11%, 30% 100%, 0 45%, 11% 45%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: CanvasText;
  }
  input[type='checkbox']:checked::before {
    transform: scale(1);
  }
  opacity: 0.6;
}
