.menu.mobile {
  display: none !important;
}
.items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: fixed;
  z-index: 999;
  width: 96%;
  height: 70px;
  padding: 0 2%;
  .logo {
    padding: 0 1rem;
    width: 20%;
    img {
      height: 3rem;
    }
  }
  .menu.desktop {
    width: 80%;
    ul {
      padding-top: 2rem;
      padding-bottom: 0.5rem;
      margin: 0;
      display: flex;
      justify-content: flex-end;
      list-style: none;
      li {
        padding: 0 1rem;
        button {
          transition: all 0.3s ease;
          color: #808080;
          background-color: transparent;
          border: none;
          outline: none;
          padding-bottom: 0.4rem;
          border-bottom: solid 2px transparent;
        }
      }
      .active button {
        color: #000;
        border-bottom: solid 2px var(--orange);
      }
    }
  }
}
.layout-content {
  padding-top: 70px;
  .flags {
    display: grid;
    position: fixed;
    z-index: 5;
    top: 80px;
    right: 10px;
    button {
      background-color: transparent;
      border: none;
      padding: 0;
      img {
        border: solid 2px transparent;
        width: 35px;
      }
    }
    .fr,
    .en {
      transition: all 0.3s ease;
      img {
        border: solid 2px var(--orange);
      }
    }
  }
}
@media all and (max-width: 768px) {
  .menu.desktop {
    display: none !important;
  }
  .menu.mobile {
    display: block !important;
    .burger {
      button {
        cursor: pointer;
        background-color: transparent;
        border: none;
        img {
          width: 30px;
        }
      }
    }
    ul {
      padding: 0;
      background-color: #fff;
      width: 100%;
      left: 0;
      position: absolute;
      list-style: none !important;
      li {
        margin: 0.5rem 0;
        text-align: center !important;
        button {
          background-color: transparent;
          border: none;
        }
      }
      li.active {
        button {
          border-bottom: solid 1px var(--orange);
        }
      }
    }
  }
}
