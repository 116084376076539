.open-folder {
  .grade-return {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    &:hover {
      background-color: transparent;
    }
    .return-icon {
      position: relative;
      display: flex;
      width: 25px;
      padding-right: 5px;
      img {
        width: 100%;
      }
    }
  }
  .inline-field {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .open-folder-container {
    background-color: #fff;
    padding: 1rem 2.5rem;
    margin-top: 2em;
    .open-folder-header {
      display: flex;
      justify-content: space-between;
      .folder-name {
        .pending {
          display: inline-block;
          padding: 0.2rem 0.6rem;
          background-color: #fff4d1;
        }
      }
    }
    .jury-inset {
      background-color: var(--violet);
      color: #fff;
      padding: 2rem 3rem;
      margin-bottom: 2rem;
      margin-top: 2rem;
      border-radius: 15px;
      .inset-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .inset-content {
        .top {
          display: flex;
          justify-content: space-around;
        }
        .bottom {
          display: flex;
          justify-content: space-around;
        }
      }
    }
    .recap {
      h5 {
        text-transform: uppercase;
        font-size: 1rem !important;
      }
      .parcours {
        margin: 2rem;
        .attachment {
          border: solid 1px rgba(0, 0, 0, 0.2);
          margin: 2rem;
          padding: 1rem;
        }
      }
    }
  }
}

