.modal-header {
  position: fixed;
  z-index: 10;
  background-color: #fff;
  max-width: 1200px;
  width: calc(100% - 64px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .modal-title {
    margin-left: 2rem;
  }
  .modal-close {
    margin-right: 1rem;
    .btn-close {
      cursor: pointer;
      background-color: transparent;
      border: none;
      position: relative;
      width: fit-content;
    }
  }
}
.modal-content {
  position: relative;
  margin-top: 55px;
  width: 100%;
  text-align: center;
}
