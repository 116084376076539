@font-face {
  font-family: 'futura';
  src: url('./fonts/futura_medium_bt.ttf') format('truetype');
}

$darkblue: #00041b;
$royalblue: #050036;
$gold: #b07d4c;
$yellow: #ffd171;
$electricblue: #0000e3;
$orange: #ff5c39;
$palesalmon: #ffeaa8;
$violet: #3f2a56;
$darkviolet: #100a16;
$pending: #ffeaa8;
$rejected: #ffcec4;
$validated: #adffd0;
:root {
  --darkblue: #{$darkblue};
  --royalblue: #{$royalblue};
  --gold: #{$gold};
  --yellow: #{$yellow};
  --electricblue: #{$electricblue};
  --orange: #{$orange};
  --palesalmon: #{$palesalmon};
  --violet: #{$violet};
  --darkviolet: #{$darkviolet};
  --pending: #{$pending};
  --rejected: #{$rejected};
  --validated: #{$validated};
}
:export {
  primary: $gold;
  secondary: $royalblue;
}

/******************/
/* General rules */
/****************/
* {
  font-family: 'futura' !important;
  &:focus-visible,
  &:focus,
  &:-webkit-direct-focus {
    outline: none !important;
    outline-color: transparent !important;
  }
}
.image-container {
  background-color: var(--royalblue);
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  img {
    width: 100%;
  }
}
html {
  background-color: #fff0ec;
}
#root {
  background-color: #fff0ec;
  min-height: 100vh;
}
.App {
  font-family: 'futura' !important;
  text-align: center;
}
h1,
h2,
h3,
h4,
label {
  font-family: 'futura' !important;
  margin: 0.5rem 0 !important;
}
select,
input,
select-option,
.MuiSelect-root {
  font-family: 'futura' !important;
  margin-bottom: 0.5rem !important;
}
span {
  font-size: 0.75rem;
}
h5 {
  margin: 0.5rem 0 !important;
  font-weight: normal !important;
}
h6 {
  position: relative;
  z-index: 10;
  color: #66605e;
  font-family: 'futura' !important;
  line-height: 1.5rem;
  margin: 0.5rem 0;
  font-weight: normal !important;
  text-transform: uppercase !important;
}
.form-h6 {
  margin-top: 0.5rem !important;
  margin-bottom: 0 !important;
}
th,
td,
.alert-message {
  font-family: 'futura' !important;
}
.MuiDataGrid-root.MuiDataGrid-autoHeight {
  color: var(--darkblue) !important;
  svg,
  span {
    color: var(--darkblue) !important;
  }
}
.MuiListItemIcon-root {
  color: #33531c;
}
.layout {
  min-height: 60vh;
}

/***************/
/* Containers */
/*************/

.general-container {
  position: relative;
  padding: 3rem 0;
  width: 60%;
  margin: auto;
}
@media all and (max-width: 550px) {
  .general-container {
    width: 95%;
  }
}
@media all and (min-width: 551px) and (max-width: 1020px) {
  .general-container {
    width: 75%;
  }
}

/************/
/* Buttons */
/**********/
button {
  cursor: pointer;
}
.btn {
  border-radius: 2px !important;
  margin: 0.5rem 0 !important;
  border: none;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  box-shadow: none !important;
  span {
    font-size: 1rem;
  }
}
.btn-fullwidth {
  width: 100% !important;
  span {
    font-size: 1.1rem !important;
    font-weight: 900 !important;
  }
}
.btn-disabled,
button:disabled {
  background-color: #adadad !important;
  border: solid 2px #adadad !important;
  cursor: default !important;
  padding: 0.8rem !important;
}
.btn-main {
  background-color: var(--orange);
  color: rgb(255, 255, 255);
  border: solid 2px var(--orange);
  padding: 0.8rem !important;
  // .disabled {
  //   background-color: var(--grey);
  // }
}
.btn-yes {
  background-color: var(--orange);
  color: rgb(255, 255, 255);
  border: solid 2px var(--orange);
  padding: 0.8rem !important;
}
.btn-no {
  background-color: #555555;
  color: rgb(255, 255, 255);
  border: solid 2px #555555;
  padding: 0.8rem !important;
}
.btn-return {
  background-color: transparent;
  display: flex;
  align-items: center;
  img {
    width: 30px;
  }
  span {
    font-size: 1rem;
  }
}
.add-button {
  margin: 1rem 0;
  button {
    border: none;
    background-color: transparent;
    color: var(--orange);
  }
}
.btn-outlined {
  background-color: transparent;
  color: var(--darkviolet);
  border: solid 2px #696969;
  padding: 0.5rem !important;
  span {
    font-size: 0.8rem;
  }
}
.btn-underline {
  background-color: transparent;
  color: var(--darkviolet);
  opacity: 0.6;
  border: none !important;
  padding: 0.5rem !important;
  span {
    border-bottom: solid 1px var(--darkviolet);
    font-size: 0.75rem;
  }
}
.lower {
  text-transform: initial !important;
}
.upper {
  text-transform: uppercase !important;
}

/**********************/
/* Form */
/********************/
.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.7rem auto;
  label {
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
    text-transform: uppercase;
    color: rgb(90, 90, 90);
    font-weight: normal;
  }
  input {
    width: 90% !important;
  }
}
.select-react {
  div {
    z-index: 100;
  }
}
.select-react,
select {
  width: 53% !important;
}
.form-required {
  font-size: 1rem !important;
  color: red;
}
input,
select,
textarea {
  width: 50%;
  background-color: #fff;
  padding: 0.8rem;
  font-weight: normal;
  color: var(--darkviolet);
  border: solid 1px #e5e5e5 !important;
  outline: none;
}
input[type='checkbox'] {
  margin: 3px !important;
}
.custom-checkbox {
  margin: 1.2rem 0;
  h6 {
    margin-bottom: 1rem !important;
  }
  .custom-check {
    display: none;
  }
  .custom-check:checked > label {
    border: solid 1px var(--orange);
  }
  label {
    margin: 0 0.5rem !important;
    cursor: pointer;
    border: solid 1px transparent;
    background-color: #fff;
    padding: 0.6rem 1rem;
  }
  .selected {
    border: solid 2px var(--orange);
  }
}
.profil {
  .selected {
    border: solid 2px var(--orange);
  }
}
.hide-show {
  position: relative;
  display: block;
  button {
    position: absolute;
    right: 5%;
    // top: 0.5rem;
  }
}
@media all and (max-width: 375px) {
  .custom-checkbox {
    display: grid;
    width: fit-content;
    label {
      margin: 0.5rem !important;
    }
  }
}

/***************/
/* Responsive */
/*************/
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media all and (max-width: 520px) {
  .select-react,
  select {
    width: 88% !important;
  }
  input,
  textarea {
    width: 80% !important;
  }
}
@media all and (max-width: 780px) {
  h1 {
    font-size: 2rem !important;
  }
  h2 {
    font-size: 1.7rem !important;
  }
  h3 {
    font-size: 1.4rem !important;
  }
  h4 {
    font-size: 1.2rem !important;
  }
  h5 {
    font-size: 1rem !important;
  }
  h6 {
    font-size: 0.6rem !important;
  }
  p {
    font-size: 1rem !important;
  }
  .container-flex {
    display: block !important;
  }
  .container-25 {
    width: 100% !important;
  }
  .container-50 {
    width: 100% !important;
  }
}
@media all and (min-width: 781px) and (max-width: 1024px) {
  h1 {
    font-size: 2.5rem !important;
  }
  h2 {
    font-size: 2rem !important;
  }
  h3 {
    font-size: 1.5rem !important;
  }
  h4 {
    font-size: 1rem !important;
  }
  h5 {
    font-size: 0.8rem !important;
  }
  h6 {
    font-size: 0.8rem !important;
  }
  p {
    font-size: 0.8rem !important;
  }
  button {
    font-size: 1rem;
  }
}
@media all and (min-width: 1025px) and (max-width: 1920px) {
  h1 {
    font-size: 3rem !important;
  }
  h2 {
    font-size: 2.5rem !important;
  }
  h3 {
    font-size: 2rem !important;
  }
  h4 {
    font-size: 1.5rem !important;
  }
  h5 {
    font-size: 1.2rem !important;
  }
  h6 {
    font-size: 0.8rem !important;
  }
  p {
    font-size: 1rem !important;
  }
  button {
    font-size: 1rem;
  }
}
@media all and (min-width: 1921) {
  h1 {
    font-size: 4rem !important;
  }
  h2 {
    font-size: 3.5rem !important;
  }
  h3 {
    font-size: 3rem !important;
  }
  h4 {
    font-size: 2.5rem !important;
  }
  h5 {
    font-size: 2.5rem !important;
  }
  h6 {
    font-size: 2.5rem !important;
  }
  p {
    font-size: 2rem !important;
  }
  button {
    font-size: 2rem;
    --padding-bottom: 2rem !important;
    --padding-top: 2rem !important;
  }
}
