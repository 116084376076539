.agent-list {
  .agent-header {
    display: flex;
    margin-bottom: 2rem;
    .left {
      width: 30%;
      padding: 1rem;
      background-color: #000;
      color: #fff;
      border-radius: 20px;
    }
    .right {
      padding: 1rem;
    }
  }
  .agent-title-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .agent-filters {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      input {
        width: 70%;
      }
      select {
        width: 30%;
        margin-left: 1rem !important;
      }
    }
  }
}
