.delete-apply-modal {
  .MuiPaper-root {
    width: 50%;
    margin: 10px !important;
    .modal-header {
      position: relative !important;
      width: 100% !important;
    }
    .modal-content {
      margin-top: 2rem;
      .modal-buttons {
        display: flex;
        justify-content: center;
        button {
          margin: 1rem !important;
        }
      }
    }
  }
}
.applied {
  .application-list {
    display: grid;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .application-card {
      background-color: #fff;
      padding: 1rem;
      margin: 0.5rem 0;
      -webkit-box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.25);
      &-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: 1rem;
        .action-buttons {
          text-align: right;
          .remove-application {
            border: none;
            background-color: transparent;
            color: red;
          }
        }
        p {
          color: #8b8b8b;
          margin: 0;
        }
      }
      &-content {
        padding: 0.2rem 1.5rem;
        display: flex;
        justify-content: space-between;
        .application-info {
          p {
            padding: 0.3rem;
            text-align: center;
            border-radius: 5px;
          }
          .orange {
            background-color: #ffeaa8;
          }
          .red {
            background-color: #ffcec4;
          }
          .green {
            background-color: #adffd0;
          }
        }
      }
    }
  }
}
@media all and (max-width: 425px) {
  .application-card-header {
    flex-direction: column-reverse;
    .action-buttons {
      width: 100%;
    }
  }
}
@media all and (max-width: 700px) {
  .delete-apply-modal {
    .MuiPaper-root {
      width: 100%;
    }
  }
  .application-card-content {
    display: block !important;
    .application-info {
      text-align: center;
      width: 100%;
    }
  }
}
