.image-upload {
  > input {
    display: none;
  }
  .upload {
    margin: 0.5rem !important;
    cursor: pointer;
    width: 18vw;
    height: 10vw;
    background-color: #fff;
    position: relative;
    display: grid;
    -webkit-box-shadow: 0px 0px 2px 0px #9462ca;
    box-shadow: 0px 0px 2px 0px #9462ca;
    p {
      margin: 0;
      padding-top: 1rem;
      text-align: center;
    }
    span {
      opacity: 0.5;
      text-align: center;
    }
    img {
      margin: auto;
      padding-bottom: 1.5rem;
      width: 30px;
    }
  }
}
@media all and (max-width: 938px) and (min-width: 768px) {
  .image-upload {
    .upload {
      width: 50%;
      height: 18vw;
      img {
        width: 25px;
      }
    }
  }
}
@media all and (max-width: 767px) and (min-width: 426px) {
  .image-upload {
    .upload {
      height: 24vw;
      width: 60%;
      img {
        width: 25px;
      }
    }
  }
}
@media all and (max-width: 425px) {
  .image-upload {
    .upload {
      height: 30vw;
      width: 65%;
      img {
        width: 20px;
      }
    }
  }
}
